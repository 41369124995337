import { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import "@reach/combobox/styles.css";
import { isInProtectedArea } from "./utils";
import { useLanguage } from "../context/Language";

import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'; // assuming you use react-i18next for translations


export default function Places({
  geoJson,
  setSelectedLocation,
  setIsLocationProtected,
  selectedLocation,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_MAP_API_KEY?.trim(),
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      setIsLocationProtected={setIsLocationProtected}
      geoJson={geoJson}
      setSelectedLocation={setSelectedLocation}
      selectedLocation={selectedLocation}
    />
  );
}

export function Map({
  geoJson,
  setSelectedLocation,
  selectedLocation,
  setIsLocationProtected,
}) {
  const [map, setMap] = useState(null);
  const [localGeoJson, setLocalGeoJson] = useState(null); // State to store GeoJSON data
  //@ts-ignore
  const { zoom, setZoom } = useLanguage();
  const mapRef = useRef(null);
  const defaultCenter = {
    lat: -8.410614002652656, // Default to San Francisco
    lng: 115.18877457548977,
  };
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  // const [isPlacingMarker, setIsPlacingMarker] = useState(false);
  const isPlacingMarker = useRef(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    if (map && geoJson?.geometry?.coordinates?.length > 0) {
      // Clear existing GeoJSON data if any
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Add the new GeoJSON data
      map.data.addGeoJson(geoJson);
      map.data.setStyle({
        fillColor: geoJson?.iucn_color,
        strokeColor: geoJson?.iucn_color,
        strokeWeight: 2,
        fillOpacity: 0.4,
      });
    } else if (map) {
      // Clear the map data if geoJson is null or empty
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
  }, [map, geoJson]);

  // Function to load the map
  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMapCenter(userLocation);
        },
        (error) => {
          console.error("Error getting location", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  useEffect(() => {
    const addGeoJsonToMap = (data, color) => {
      if (map && data?.geometry?.coordinates?.length > 0) {
        // Clear existing GeoJSON data if any
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });
        // Add the new GeoJSON data
        map.data.addGeoJson(data);
        map.data.setStyle({
          fillColor: color || "#FF0000",
          strokeColor: color || "#FF0000",
          strokeWeight: 2,
          fillOpacity: 0.4,
        });
      } else if (map) {
        // Clear the map data if geoJson is null or empty
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });
      }
    };
    
    // Check both geoJson from props and the localGeoJson state
    if (geoJson && geoJson.geometry?.coordinates?.length > 0) {
      addGeoJsonToMap(geoJson, geoJson?.iucn_color);
    }
    if (localGeoJson && localGeoJson.geometry?.coordinates?.length > 0) {
      addGeoJsonToMap(localGeoJson, localGeoJson?.iucn_color);
    }
  }, [map, geoJson, localGeoJson]);
  
  const onMapLoad = useCallback((mapInstance) => {
    mapRef.current = mapInstance;
    setMap(mapInstance);

    // Add custom control
    const controlDiv = document.createElement("div");
    controlDiv.style.padding = "10px";
    //@ts-ignore
    controlDiv.index = 1;

    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to place marker";
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "📌";
    controlUI.appendChild(controlText);

    controlUI.addEventListener("click", () => {
      // setIsPlacingMarker(true);
      //@ts-ignore
      isPlacingMarker.current = true;
    });

    mapInstance.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      controlDiv
    );
  }, []);

  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  };

  const mapOptions = {
    scrollwheel: true, // This enables zoom on scroll without Ctrl
    gestureHandling: "greedy",
    mapTypeId: "hybrid",
    // You can also disable specific controls individually:
    // zoomControl: false,
    // mapTypeControl: false,
    // scaleControl: false,
    // streetViewControl: false,
    // rotateControl: false,
    // fullscreenControl: false
  };

  // const handleMapClick = useCallback(
  //   (event) => {
  //     if ((isPlacingMarker.current = true)) {
  //       const lat = event.latLng.lat();
  //       const lng = event.latLng.lng();
  //       setSelectedLocation({ lat, lng });

  //       setSelectedLocation({
  //         ...selectedLocation,
  //         lat: lat,
  //         lng: lng,
  //       });

  //       isInProtectedArea([lng, lat]).then((result) => {
  //         // setGeoJson(result[1]);

  //         if (!result[0] === true) {
  //           setIsLocationProtected(true);
  //         } else {
  //           setIsLocationProtected(false);
  //         }
  //       });
  //       setZoom(20);
  //       isPlacingMarker.current = false;
  //     }
  //   },
  //   [setSelectedLocation]
  // );

  const handleMapClick = useCallback(
    (event) => {
      if (isPlacingMarker.current === true) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ lat, lng });

        setSelectedLocation((prevState) => ({
          ...prevState,
          lat: lat,
          lng: lng,
        }));
        isInProtectedArea([lng, lat]).then((result) => {
          console.log(result, "result handleMapClick")
          setLocalGeoJson(result[1])
          
          // Handle different protection statuses
          if (result[3] === 'red') {
            setIsLocationProtected(true);
            Swal.fire({
              icon: 'warning', // Updated the icon to 'warning' as 't("Warning")' would not be recognized by SweetAlert2
              title: `${result[2]}`,
              text: t("Please, change to another area that is not protected to proceed"),
            });
          } else if (result[3] === 'orange') {
            setIsLocationProtected(false);
            Swal.fire({
              icon: 'warning',
              title: `${result[2]}`,
              text: t("This protected area is allowed for intensive uses (typically agriculture, forestry, and tourism). Do you want to proceed?"),
            });
          } else if (result[3] === 'yellow') {
            setIsLocationProtected(false);
            Swal.fire({
              icon: 'warning',
              title: `${result[2]}`,
              text: t("This protected area is allowed on sustainable use of environmental products and services (typically hunting, grazing, and management of natural resources). Do you want to proceed?"),
            });
          } else {
            setIsLocationProtected(false);
          }
        });
        setZoom(20);
        isPlacingMarker.current = false;
      }
    },
    // [setSelectedLocation, selectedLocation, t]
    [setSelectedLocation, t, setIsLocationProtected, setZoom]
  );

  return (
    <>
      <GoogleMap
        options={mapOptions}
        zoom={zoom}
        onLoad={onMapLoad}
        onZoomChanged={handleZoomChanged}
        center={{
          lat: selectedLocation?.lat || mapCenter.lat,
          lng: selectedLocation?.lng || mapCenter.lng,
        }}
        mapContainerClassName="map-container"
        onClick={handleMapClick}
      >
        {selectedLocation && (
          <Marker
            onDragEnd={(marker) => {
              setSelectedLocation({
                ...selectedLocation,
                lat: marker.latLng.lat(),
                lng: marker.latLng.lng(),
              });
              isInProtectedArea([
                marker.latLng.lng(),
                marker.latLng.lat(),
              ]).then((result) => {
                console.log(result, "result Marker")
                setLocalGeoJson(result[1]);
                // if (!result[0] === true) {
                //   setIsLocationProtected(true);
                // } else {
                //   setIsLocationProtected(false);
                // }
                if (result[3] === 'red') {
                  setIsLocationProtected(true);
                  Swal.fire({
                    icon: 'warning', // Updated the icon to 'warning' as 't("Warning")' would not be recognized by SweetAlert2
                    title: `${result[2]}`,
                    text: t("Please, change to another area that is not protected to proceed"),
                  });
                } else if (result[3] === 'orange') {
                  setIsLocationProtected(false);
                  Swal.fire({
                    icon: 'warning',
                    title: `${result[2]}`,
                    text: t("This protected area is allowed for intensive uses (typically agriculture, forestry, and tourism). Do you want to proceed?"),
                  });
                } else if (result[3] === 'yellow') {
                  setIsLocationProtected(false);
                  Swal.fire({
                    icon: 'warning',
                    title: `${result[2]}`,
                    text: t("This protected area is allowed on sustainable use of environmental products and services (typically hunting, grazing, and management of natural resources). Do you want to proceed?"),
                  });
                } else {
                  setIsLocationProtected(false);
                }
              });
            }}
            draggable
            position={{
              lat: selectedLocation?.lat,
              lng: selectedLocation?.lng,
            }}
          />
        )}
      </GoogleMap>
    </>
  );
}
export function MapForReport({ lat, lng }) {
  //@ts-ignore
  const { zoom } = useLanguage();
  // Function to load the map
  //   // Load the GeoJSON data into the map
  //   map.data.addGeoJson({
  //     type: "FeatureCollection",
  //     features: [
  //       {
  //         type: "Feature",
  //         properties: {},
  //         geometry: {
  //           coordinates: [
  //             [-73.95831944819373, 40.80011560101866],
  //             [-73.98200144349565, 40.7680563496657],
  //             [-73.97340005048385, 40.76460749246576],
  //             [-73.94922217203022, 40.797048286892306],
  //             [-73.95818135154668, 40.80048141633816],
  //           ],
  //           type: "LineString",
  //         },
  //       },
  //     ],
  //   });

  //   // Optionally, set a style for the GeoJSON features
  //   map.data.setStyle({
  //     icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  //   });
  // };
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: "hybrid",
    // You can also disable specific controls individually:
    // zoomControl: false,
    // mapTypeControl: false,
    // scaleControl: false,
    // streetViewControl: false,
    // rotateControl: false,
    // fullscreenControl: false
  };

  return (
    <>
      <GoogleMap
        zoom={zoom}
        options={mapOptions}
        // streetView={false}
        // {geoJson?.geometry?.coordinates?.length > 0 && (
        // )}
        center={{
          lat: lat || -0.023559,
          lng: lng || 37.906193,
        }}
        mapContainerClassName="map-container"
      >
        <Marker
          position={{
            lat: lat,
            lng: lng,
          }}
        />
      </GoogleMap>
    </>
  );
}

// export const PlacesAutocomplete = ({ setSelected, setCenter, setAddress }) => {
//   const {
//     ready,
//     value,
//     setValue,
//     suggestions: { status, data },
//     clearSuggestions,
//   } = usePlacesAutocomplete();

//   const latLngRegex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;

//   const handleSelect = async (input) => {
//     setValue(input, false);
//     clearSuggestions();

//     if (latLngRegex.test(input)) {
//       // Input is a coordinate pair
//       const [lat, lng] = input.split(",").map(Number);
//       setSelected({ lat, lng });
//       setCenter({ lat, lng });
//     } else {
//       // Input is an address
//       setAddress(input);

//       const results = await getGeocode({ address: input });
//       const { lat, lng } = await getLatLng(results[0]);
//       setSelected({ lat, lng });
//       setCenter({ lat, lng });
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && latLngRegex.test(value)) {
//       handleSelect(value);
//     }
//   };

//   return (
//     <>
//       <Combobox onSelect={handleSelect}>
//         <ComboboxInput
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
//           onKeyDown={handleKeyDown} // Detect Enter key press
//           disabled={!ready}
//           className="combobox-input"
//           placeholder="Search address or enter lat,lng"
//         />
//         <ComboboxPopover>
//           <ComboboxList>
//             {status === "OK" &&
//               data.map(({ place_id, description }) => (
//                 <ComboboxOption key={place_id} value={description} />
//               ))}
//           </ComboboxList>
//         </ComboboxPopover>
//       </Combobox>
//     </>
//   );
// };
