// @ts-nocheck

import NewsFeed from "../../components/NewsFeed";
import MainDashboard from "../../components/dashboards/main_dashboard";
import Select from "react-select";
import { Button, Modal } from "flowbite-react";
import { axiosProfitablity } from "../../api/axiosPrivate";
import { useCallback, useEffect, useRef, useState } from "react";
import ButtonLoading from "../../components/ButtonLoading";
import AutocompleteInput from "../../components/AutocompleteInput";
import { useLoadScript } from "@react-google-maps/api";
import { isInProtectedArea } from "../../components/utils";
import Swal from "sweetalert2";
import { format } from "date-fns";
import MapComponent from "../../components/MapComponent";
import Gauge from "../../components/Gauge";
import TopGauge from "../../components/topgauge";
import TopGaugeCattle from "../../components/TopGaugeCattle";
import { scoreTooltipData } from "../../data/requestData";
import BeatLoader from "react-spinners/BeatLoader";
import { result, sum } from "lodash";
import PdfLivestock from "../../components/PdfLivestock";
import html2pdf from "html2pdf.js";
import generatePDF from "react-to-pdf";
import { changeKeys, pdfOptions } from "../../utils";
import GradientBarChart from "../../components/GradientBarchart";
import IdleGauge from "../../components/IdleGauge";
import Places from "../../components/PlacesAutocomplete";
import ProgressBarHome from "../../components/ProgressBarHome";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/Language";
import { usePayment } from "../../context/Payment";
import { useRiskDatas } from "../../context/LivestockRiskData"; // Import the context

export interface MapLocation {
  lat: number | null;
  lng: number | null;
}
const options = [
  { value: "dairy_cattle", label: "Dairy cattle" },
  { value: "beef_cattle_breeding", label: "Beef cattle breeding" },
  { value: "pig_full_cycle", label: "Pigs" },
  { value: "poultry_broilers", label: "Poultry broilers" },
  { value: "poultry_hens", label: "Poultry hens" },
];
const override: CSSProperties = {
  display: "flex",
  margin: "0 auto",
  borderColor: "red",
  textAlign: "center",
  justifyContent: "center",
  top: "50%",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const getFormattedFutureDate = (numberOfMonths: number): string => {
  const today = new Date();
  today.setMonth(today.getMonth() + numberOfMonths);
  return format(today, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};

const getDateToday = (): string => {
  const today = new Date();
  return format(today, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};

const getFormattedTodayDate = (selectedDate: Date): string => {
  return format(selectedDate, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};
const libraries = ["places"];

export default function Livestock() {
  const [step, setStep] = useState(0);
  const [geoJson, setGeoJson] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [option, setOption] = useState(null);
  const { credits, setCredits } = usePayment();
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const [profitData, setProfitData] = useState({});
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [sumbmit, setSumbit] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLocationProtected, setIsLocationProtected] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [toMonths, setToMonths] = useState(1);
  const [breedTypes, setBreedTypes] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [update, setUpdate] = useState(true);
  const [livestock, setLivestock] = useState([]);
  const selectedOption = useRef(null);
  const type = useRef(null);
  const scrollRef = useRef(null);
  const [firstQuestion, setFirstQuestion] = useState([]);
  const elementRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValueAuto, setInputValueAuto] = useState<string>("");
  const [currentBreed, setCurrentBreed] = useState(null);
  const [inputValue, setinputValue] = useState(null);
  const [topThreeRisks, setTopThreeRisks] = useState([]);
  const [topThreeIndicis, setTopThreeIndicis] = useState([]);
  const [isComputed, setIsComputed] = useState(false);
  const { t, i18n } = useTranslation("home");
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const { setTopThreeIndiciss, setTopThreeRiskss, setProfitDatas } = useRiskDatas(); // Use context values

  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [riskId, setRiskId] = useState("");

  const languageMapping = {
    zh: "chinese",
    en: "english",
    tl: "filipino",
    fr: "french",
    de: "german",
    hi: "hindi",
    id: "indonesian",
    ja: "japanese",
    pt: "portuguese",
    ru: "russian",
    es: "spanish",
  };
  const climate_indices = [t("Drought"), t("Temperature humidity")];

  const water_indices = [t("Water stress"), t("Ground Water Availability")];

  const profitability_indices = [t("Profitability")];
  const role = localStorage.getItem("empType");
  const handleSubmit = async (state) => {
    localStorage.setItem("selectedBreed", currentBreed);
    localStorage.setItem("selectedBusiness", option);

    if (role === "single_user") {
      if (credits < 1) {
        Swal.fire({
          icon: "error",
          title: t("Your Credits Has Expired"),
          text: t("Please top up to keep using searches"),
        });
        return;
      }

      if (credits < 6) {
        Swal.fire({
          icon: "info",
          title: t("Low Credits"),
          text: t("You have low credits"),
        });
      }
    }
    const body = {
      livestock_type: option.value,
      parameters: { ...state },
    };

    body.risk_score_id = riskId;

    //const URL_PROFIT = `/compute-profitability-risk`;
    const URL_PROFIT = `/compute-profitability-risk-score-only`;
    setIsLoading(true);
    axiosProfitablity<any>({ method: "POST", url: URL_PROFIT, data: body })
      .then((data) => {
        setCredits((s) => s - 1);
        setOption(null);
        setCurrentBreed("");
        scrollToDiv();
        setProfitData(data?.data);
        setProfitDatas(data?.data);
        setIsComputed(true);
        setIsLoading(false);
        const specificScores = [
          "water_scores",
          "profitability_scores",
          "climate_scores",
        ];
        const result = [];

        specificScores.forEach((scoreKey) => {
          if (data?.data?.total_scores.hasOwnProperty(scoreKey)) {
            result.push(data?.data?.total_scores[scoreKey]);
          }
        });

        const keysArray = [
          "ground_water_risk",
          "water_stress_risk",
          "temperature_humidity_index_risk",
          "drought_risk",
          "profitability_risk",
        ];
        const resultArray = [];

        result?.forEach((obj) => {
          keysArray.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
              resultArray.push({ [key]: obj[key] });
            }
          });
        });
        resultArray.sort((a, b) => {
          const aValue = Object.values(a)[0];
          const bValue = Object.values(b)[0];
          return bValue - aValue;
        });

        const keyMapping = {
          ground_water_risk: t("Ground Water Availability"),
          water_stress_risk: t("Water stress"),
          temperature_humidity_index_risk: t("Temperature humidity"),
          drought_risk: t("Drought"),
          profitability_risk: t("Profitability"),
        };
        const topRisks = changeKeys(resultArray, keyMapping);

        const firstThreeKeys = [];
        const firstThreeValues = [];

        // Loop over the first three objects
        for (let i = 0; i < 3; i++) {
          const obj = topRisks[i];
          for (const key in obj) {
            // Push key to firstThreeKeys if it's not already there
            if (!firstThreeKeys.includes(key)) {
              firstThreeKeys.push(key);
            }
            // Push value to firstThreeValues
            firstThreeValues.push(obj[key]);
          }
        }

        setTopThreeIndicis(firstThreeKeys);
        setTopThreeRisks([{ data: firstThreeValues }]);
        setTopThreeIndiciss(firstThreeKeys);
        setTopThreeRiskss([{ data: firstThreeValues }]);

        if (Math.floor(data?.data?.total_scores?.composite_total_risk) > 74) {
          Swal.fire({
            icon: t("Error"),
            title: "<h5 style='color:red'>" + "Error" + "</h5>",
            text: t(
              "The overall risk of doing this project is too high! Do not proceed!"
            ),
          });
          return;
        }
        if (
          Math.floor(
            data?.data?.total_scores?.profitability_scores?.profitability_risk
          ) > 74
        ) {
          Swal.fire({
            icon: t("Warning"),
            title: "<h5 style='color:orange'>" + t("Warning") + "</h5>",
            text: t(
              "The Profitability Risk is too high! We recommend not to proceed with this project."
            ),
          });
        }
        setSuggestionLoading(true);

        axiosProfitablity<any>({
          method: "GET",
          url: `/get-ai-suggestions/${data?.data.risk_score_id.risk_score_id}`,
          data: JSON.stringify(body),
        })
          .then((data) => {
            setProfitData((prev) => ({
              ...prev,
              total_scores: {
                ...prev.total_scores,
                adaptations: data.data.total_scores.adaptations,
              },
            }));
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            setSuggestionLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: t("Error"),
          title: t("Error"),
          text: t("Something went wrong"),
        });
      });
    // setOption(null);
    setSumbit({});
    setStep(0);
    setQuestions([]);
  };

  const handleFromDateChange = (selectedDate: Date) => {
    const fDate = getFormattedTodayDate(selectedDate);
    setFromDate(fDate);
    setFormValues({
      ...formValues,
      startDate: fromDate,
    });
  };

  const handleToDateChange = () => {
    const fDate = getFormattedFutureDate(1);
    setToDate(fDate);
    setFormValues({
      ...formValues,
      endDate: toDate,
    });
  };
  const handlelosePopUp = (e: any) => {
    if (e.target.id === "ModelContainer") {
      setOpenPopup(false);
    }
  };
  useEffect(() => {
    // Set initial values for "from date" and "to date" when the component is mounted
    getLivestock();

    const today = new Date();
    handleFromDateChange(today);
    handleToDateChange();
  }, []);

  const handlePrint = () => {
    const printContents = document.getElementById("printablediv")?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };

  const getTargetElement = () => document.getElementById("printablediv");

  const handleDownloadPDF = () => {
    // const modalContent = document.getElementById("printablediv");
    // console.log("generatePDF:modalContent: ", modalContent);
    // html2pdf()
    //   .set({
    //     pagebreak: { mode: "avoid-all" },
    //   })
    //   .from(modalContent)
    //   .save("modal_content.pdf");
    generatePDF(getTargetElement, pdfOptions);
  };
  const handleChanget = async (item) => {
    const BREED_TYPE = `/breed-type/${item.value}`;
    setOption(item);

    axiosProfitablity<any>({
      method: "GET",
      url: BREED_TYPE,
    })
      .then((data) => {
        const breedData = data.data.map((item) => {
          return {
            label: item,
            value: item,
          };
        });

        setBreedTypes(breedData);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  async function handleChangeFirstQuestion(item) {
    setSumbit({ ...sumbmit, [type.current]: item.value });

    setError(null);
    setOpenModal(false);
    const REST_QUESTIONS = `/questionnaire/others?lang=${languageMapping[selectedLanguage]}`;
    const payload = {
      livestock_type: selectedItem,
      system: item.value,
    };
    axiosProfitablity<any>({
      method: "POST",
      url: REST_QUESTIONS,
      data: payload,
    })
      .then((data) => {
        setQuestions(data?.data?.questionnaires);
        setOpen(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  const handleChange = (item) => {
    selectedOption.current = item;
    if (sumbmit[type.current]) {
      setSumbit({ ...sumbmit, [type.current]: selectedOption.current.value });
      selectedOption.current = null;
    }

    setError(null);
  };
  const handleNext = () => {
    if (error) return;
    if (sumbmit[type.current]) {
      setStep(step + 1);
      return;
    }
    if (inputValue) {
      setStep(step + 1);
      setSumbit((prevState) => ({
        ...prevState,
        [type.current]: Number(inputValue),
      }));
      setinputValue(null);
      setError(null);
    } else {
      if (selectedOption.current) {
        setStep(step + 1);
        setSumbit({ ...sumbmit, [type.current]: selectedOption.current.value });

        selectedOption.current = null;
        // setSelectedOption(null);
        type.current = null;
        setError(null);
      } else {
        // setError("Please fill the option to move to next");
        window.alert("Fill the question");
      }
    }
  };
  const scrollToDiv = () => {
    if (scrollRef?.current) {
      // Scroll to the div using scrollIntoView
      scrollRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlePrev = () => {
    setStep(step - 1);
    selectedOption.current = null;
    setError(null);
  };

  const handleChangeMonth = (event) => {
    let months = parseInt(event.target.value);
    if (months < 1) {
      months = 1;
    }
    setToMonths(months);
    const tDate = getFormattedFutureDate(months);
    const fDate = getDateToday;
    setFromDate(fDate);
    setToDate(tDate);
    setFormValues({
      ...formValues,
      startDate: fromDate,
      endDate: toDate,
    });
    const latitude = selectedLocation?.lat;
    const longitude = selectedLocation?.lng;

    // Update the form with the selected crop name
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handleBlur = () => {
    if (toMonths < 1 || isNaN(toMonths)) {
      setToMonths(1);
      const tDate = getFormattedFutureDate(1);
      const fDate = getDateToday;
      setFromDate(fDate);
      setToDate(tDate);
      setFormValues({
        ...formValues,
        startDate: fromDate,
        endDate: toDate,
      });
    }
  };
  const handleMarkerPlaced = ({ lat, lng }) => {
    setSelectedLocation({ lat, lng });
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      latitude: lat,
      longitude: lng,
    });

    isInProtectedArea([lng, lat]).then((result) => {
      setGeoJson([result[1]]);
      // console.log(result, "res res");
      // if (!result[0] === true) {
      //   setIsLocationProtected(true);
      //   Swal.fire({
      //     icon: t("Warning"),
      //     title: "STOP.",
      //     text: t("You have selected a Protected Area"),
      //   });
      // } else {
      //   setIsLocationProtected(false);
      // }
      if (result[3] === 'red') {
        setIsLocationProtected(true);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("Please, change to another area that is not protected to proceed"),
        });
      } else if (result[3] === 'orange') {
        setIsLocationProtected(false);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("This protected area is allowed for intensive uses (typically agriculture, forestry, and tourism). Do you want to proceed?"),
        });
      } else if (result[3] === 'yellow') {
        setIsLocationProtected(false);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("This protected area is allowed on sustainable use of environmental products and services (typically hunting, grazing, and management of natural resources). Do you want to proceed?"),
        });
      } else {
        setIsLocationProtected(false);
      }
    });
  };

  const handleMarkerClear = () => {};
  const handleLocationSelect = (
    location: { lat: number; lng: number; address: string } | null,
    address: string
  ) => {
    setSelectedLocation(location);
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      longitude: location.lng,
      latitude: location.lat,
      locationName: address,
    });
    isInProtectedArea([location.lng, location.lat]).then((result) => {
      setGeoJson(result[1]);
      // if (!result[0] === true) {
      //   setIsLocationProtected(true);
      //   Swal.fire({
      //     icon: t("Warning"),
      //     title: t("You have selected a protected area."),
      //     text: t("Select an area that is not protected to proceed"),
      //   });
      // } else {
      //   setIsLocationProtected(false);
      // }
      if (result[3] === 'red') {
        setIsLocationProtected(true);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("Please, change to another area that is not protected to proceed"),
        });
      } else if (result[3] === 'orange') {
        setIsLocationProtected(false);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("This protected area is allowed for intensive uses (typically agriculture, forestry, and tourism). Do you want to proceed?"),
        });
      } else if (result[3] === 'yellow') {
        setIsLocationProtected(false);
        Swal.fire({
          icon: t("Warning"),
          title: `${result[2]}`,
          text: t("This protected area is allowed on sustainable use of environmental products and services (typically hunting, grazing, and management of natural resources). Do you want to proceed?"),
        });
      } else {
        setIsLocationProtected(false);
      }
    });
  };
  function getFormQuestions(type, item) {
    const CHECK_SCORE = `/compute-climate-water-risk`;
    const URL_Questions = `/questionnaire/system-only/${type}?lang=${languageMapping[selectedLanguage]}`;

    const payload = formValues;

    delete payload.crop;
    payload.livestock_type = selectedItem;
    payload.breed_type = item.value;
    Swal.fire({
      title: t("Evaluating location"),
      html: t("Please wait..."),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axiosProfitablity<any>({ method: "POST", url: CHECK_SCORE, data: payload })
      .then((data) => {
        Swal.close();
        setRiskId(data?.data?.risk_score_id.risk_score_id);

        if (
          Math.floor(
            data?.data?.total_scores?.climate_scores?.composite_climate_risk
          ) < 74 &&
          Math.floor(
            data?.data?.total_scores?.water_scores?.composite_water_risk
          ) < 74
        ) {
          setOpenModal(true);
          setIsLoading(true);
          axiosProfitablity<any>({ method: "GET", url: URL_Questions })
            .then((data) => {
              setQuestions(data?.data?.questionnaires);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        }

        if (
          Math.floor(
            data?.data?.total_scores?.climate_scores?.composite_climate_risk
          ) > 74 &&
          Math.floor(
            data?.data?.total_scores?.water_scores?.composite_water_risk
          ) > 74
        ) {
          Swal.fire({
            icon: t("Error"),
            title: "<h5 style='color:red'>" + t("Error") + "</h5>",
            text: t("The Climate and Water Risk is too high. Do not proceed!"),
          });
          return;
        }
        if (
          Math.floor(
            data?.data?.total_scores?.water_scores?.composite_water_risk
          ) > 74
        ) {
          Swal.fire({
            icon: t("Error"),
            title: "<h5 style='color:#f27474'>" + "Error" + "</h5>",
            text: t("The Water Risk is too high! Do not proceed!"),
          });
          return;
        }

        if (
          Math.floor(
            data?.data?.total_scores?.climate_scores?.composite_climate_risk
          ) > 74
        ) {
          Swal.fire({
            icon: t("Warning"),
            title: "<h5 style='color:orange'>" + t("Warning") + "</h5>",
            text: t(
              "The area you selected has very high climate  risk. Do you still want to compute the score?"
            ),
            showDenyButton: true,
            // showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
          }).then((result) => {
            if (result.isConfirmed) {
              setIsLoading(true);
              setOpenModal(true);
              axiosProfitablity<any>({ method: "GET", url: URL_Questions })
                .then((data) => {
                  setFirstQuestion(data?.data?.questionnaires);
                  setIsLoading(false);
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
            }

            if (result.isDenied || result.isDismissed) {
              return;
            }
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  const handleReportClick = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  };
  async function getLivestock() {
    const GET_LIVESTOCK = `/livestock-type`;
    axiosProfitablity<any>({ method: "GET", url: GET_LIVESTOCK })
      .then((data) => {
        const resultArray = [];
        const fullData = data.data;
        for (const key of Object.keys(fullData)) {
          resultArray.push({ label: t(fullData[key]), value: key });
        }
        setLivestock(resultArray);
        // });
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  const pickColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#6ebd5f";
    } else if (score > 30 && score <= 40) {
      return "#b0bf58";
    } else if (score > 40 && score <= 50) {
      return "#f6ca5a";
    } else if (score > 50 && score <= 60) {
      return "#fac25d";
    } else if (score > 60 && score <= 70) {
      return "#fa9359";
    } else if (score > 70 && score <= 100) {
      return "#fc5874";
    } else {
      return "#e5e7eb";
    }
  };

  const googleMapsApiKey = import.meta.env.VITE_MAP_API_KEY?.trim();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    // @ts-ignore
    libraries: libraries,
  });

  const handlesub = () => {
    if (inputValue) {
      setStep(step + 1);
      setSumbit((prevState) => ({
        ...prevState,
        [type.current]: Number(inputValue),
      }));
      setSumbit((prev) => {
        handleSubmit(prev);
        return prev;
      });

      setinputValue(null);
      setError(null);
      setOpen(false);
    } else {
      if (selectedOption.current) {
        setStep(step + 1);
        setSumbit({ ...sumbmit, [type.current]: selectedOption.current.value });
        setSumbit((prev) => {
          handleSubmit(prev);
          return prev;
        });
        selectedOption.current = null;
        // setSelectedOption(null);
        type.current = null;
        setError(null);
        setOpen(false);
      } else {
        window.alert("Fill the question");
        return;
      }
    }
  };

  function handleChangeBreed(item) {
    setCurrentBreed(item.value);
  }
  useEffect(() => {
    const savedBreed = localStorage.getItem("selectedBreed");
    const savedOption = localStorage.getItem("selectedBusiness");
    if (savedBreed) {
      setCurrentBreed(savedBreed); // Set the current breed from localStorage
    }
    if (savedOption) {
      setOption(savedOption);
    }
  }, []);

  return (
    <>
      <MainDashboard>
        <>
          <div className="flex flex-col md:grid md:grid-cols-2  lg:grid-cols-2  xl:flex xl:flex-row  gap-4">
            <form
              onSubmit={handleSubmit}
              className="w-[100%] xl:w-[28%] bg-white border border-gray-400 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]  space-y-5"
            >
              <div>
                <div className="p-5">
                  <div className="">
                    <label
                      htmlFor="input-label"
                      className="block text-sm font-medium mb-2 dark:text-white"
                    >
                      {t("Location")}
                    </label>
                    {isLoaded && (
                      <>
                        <AutocompleteInput
                          setOpenPopup={setOpenPopup}
                          setGeoJson={setGeoJson}
                          value={`LatLng(${
                            selectedLocation?.lat || "Latitude"
                          }, ${selectedLocation?.lng || "Longitude"})`}
                          onLocationSelect={handleLocationSelect}
                          setFormValues={setFormValues}
                          formValues={formValues}
                          inputValueAuto={inputValueAuto}
                          setInputValueAuto={setInputValueAuto}
                        />
                      </>
                    )}
                  </div>
                  <div className="">
                    <label
                      htmlFor="hs-select-label"
                      className="block text-[12px] font-medium mb-2 dark:text-white"
                    >
                      {t("Loan Period (Months)")}
                    </label>
                    <input
                      type="number"
                      id="input-label"
                      value={`${toMonths}`}
                      onChange={handleChangeMonth}
                      onBlur={handleBlur}
                      // data-hs-overlay="#hs-focus-datepicker-modal"
                      className="h-10 py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="Date Period in Months"
                    />
                    <p className="text-gray-500 text-[10px] pt-2">{`${fromDate} to ${toDate}`}</p>
                  </div>
                  <div className="">
                    <div className=" block text-sm font-medium mb-2 ">
                      {t("Please select the business type")}
                    </div>
                    <Select
                      placeholder={t("Select...")}
                      value={livestock.find(
                        (business) => business.value === option
                      )}
                      className="dark:text-black border-red z-50 "
                      onChange={(item) => {
                        handleChanget(item);
                        setSelectedItem(item.value);
                        // getFormQuestions(item.value);

                        // setOpen(true);
                      }}
                      options={livestock}
                    />
                  </div>
                  <div className="">
                    <div className=" block text-sm font-medium mb-2 pt-2">
                      {t("Please select the breed type")}
                    </div>
                    <Select
                      placeholder={t("Select...")}
                      value={breedTypes.find(
                        (breed) => breed.value === currentBreed
                      )}
                      className="dark:text-black border-red z-40 "
                      onChange={(item) => {
                        handleChangeBreed(item);
                        getFormQuestions(selectedItem, item);

                        // setOpen(true);
                      }}
                      options={breedTypes}
                    />
                  </div>

                  <div className="flex mb-3">
                    {/* <div className="hs-tooltip inline-block [--placement:bottom]">
                      <p className="text-sm text-gray-700 dark:text-gray-400">
                        <i className="bi bi-exclamation-circle-fill mr-1"></i>
                        Biodiversity
                      </p>
                      <span
                        className="w-80 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm dark:bg-slate-700"
                        role="tooltip"
                      >
                        Biodiversity regions are geographically defined protected
                        areas that are   effectively maintained through legal or
                        other ways to preserve biological diversity as well as
                        natural resources and related cultural resources, such as
                        forests and wildlife sanctuaries.(JUCN 1994)
                      </span>
                    </div> */}
                    {/* <div className="ml-auto flex items-center space-x-3">
                      {isLocationProtected ? (
                        <i className="bi bi-exclamation-triangle-fill text-lg text-red-500"></i>
                      ) : (
                        <i className="bi bi-patch-check-fill text-lg text-green-500"></i>
                      )}
                    </div> */}
                  </div>

                  {/* {!isLoading && !isComputed ? ( */}
                  {/* <button
                    className="h-9 py-3 px-4 inline-flex justify-center w-full items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    // data-hs-overlay="#hs-bg-gray-on-hover-cards"
                    onClick={onSubmit}
                  >
                    Compute Score
                  </button> */}
                  {/* ) : (
                    ""
                  )} */}

                  {/* {isComputed ? (
                    <button
                      className="h-9 py-3 px-4 inline-flex justify-center w-full items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                      // data-hs-overlay="#hs-bg-gray-on-hover-cards"
                      onClick={handleClearForm}
                    >
                      Compute New Score
                    </button>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </form>
            <div
              id="ModelContainer"
              onClick={handlelosePopUp}
              className={` ${
                openPopup === true ? "map-bg" : "map-bg-none"
              }  p-4  w-[100%] md:h-auto xl:w-[42%]  bg-white border   border-gray-200 shadow-sm  dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] bg-card`}
            >
              <div className="map-h-s md:h-full  " style={{ width: "" }}>
                <div
                  onClick={() => setOpenPopup(false)}
                  className="input-m absolute right-2 top-2 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
                <div className="input-m mt-4">
                  {isLoaded && (
                    <>
                      <AutocompleteInput
                        setOpenPopup={setOpenPopup}
                        setGeoJson={setGeoJson}
                        value={`LatLng(${
                          selectedLocation?.lat || "Latitude"
                        }, ${selectedLocation?.lng || "Longitude"})`}
                        onLocationSelect={handleLocationSelect}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        inputValueAuto={inputValueAuto}
                        setInputValueAuto={setInputValueAuto}
                      />
                    </>
                  )}
                </div>
                <Places
                  geoJson={geoJson}
                  setSelectedLocation={setSelectedLocation}
                  selectedLocation={selectedLocation}
                />
              </div>
            </div>
            <div
              id="hs-bg-gray-on-hover-cards"
              className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
            >
              <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-4xl lg:w-full m-3 lg:mx-auto">
                <div className="flex flex-col bg-white border shadow-sm rounded-xl ">
                  <div className="flex flex-col-reverse md:flex-row justify-between items-center py-3 px-4 border-b ">
                    <h3 className="font-bold text-gray-800 ">
                      {t("Detailed Risk Report Analysis")}
                    </h3>
                    <div>
                      {/* Col */}
                      <div className="inline-flex gap-x-2 mr-5">
                        <button
                          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  "
                          onClick={handleDownloadPDF}
                        >
                          <i className="bi bi-download"></i>
                          PDF
                        </button>
                        <button
                          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "
                          onClick={handlePrint}
                        >
                          <i className="bi bi-printer-fill"></i>
                          {t("PRINT")}
                        </button>
                      </div>
                      {/* Col */}
                      <button
                        type="button"
                        className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm "
                        data-hs-overlay="#hs-bg-gray-on-hover-cards"
                      >
                        <span className="sr-only">{t("Close")}</span>
                        <svg
                          className="w-3.5 h-3.5"
                          width={8}
                          height={8}
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="p-4 overflow-y-auto">
                    <PdfLivestock
                      profitData={profitData}
                      loanPeriod={toMonths}
                      livestock={selectedItem}
                      riskId={riskId}
                      myLocation={selectedLocation}
                      suggestionLoader={suggestionLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div ref={scrollRef} className="w-[100%] xl:w-[32%] col-span-2">
              {isComputed ? (
                <div className="bg-card text-card-foreground bg-white border border-gray-400 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                  <div className="flex flex-col gap-2 md:gap-10 space-y-1.5 p-6">
                    <h3 className=" text-xl font-bold leading-none tracking-tight ">
                      {t("Composite Risk Score")}
                    </h3>
                    <div className="flex gap-3 md:gap-0 mt-8 justify-between">
                      {isLoading ? (
                        <div className="h-[11rem]  items-center mx-auto flex">
                          <BeatLoader
                            color={"#87CEEB"}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div className="relative flex flex-col md:flex-row gap-[3%]  justify-between flex-wrap w-[100%]">
                          <div className=" flex justify-center items-center w-[100%] lg:w-[37%]">
                            <Gauge
                              level={
                                profitData?.total_scores?.composite_total_risk
                              }
                            />
                          </div>
                          <div className="pr-3 pt-3 w-[100%] lg:w-[55%]">
                            <h1 className="font-semibold text-sm leading-none tracking-tight mb-3">
                              {t("Drivers of Risk")}
                            </h1>
                            <div className="w-100">
                              <div className="w-[100%]">
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[0]}
                                </div>
                                {/* <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                    <div
                                      class="h-6  rounded-full dark:bg-blue-500"
                                      style={{
                                        backgroundColor: `${pickColor(
                                          topThreeRisks[0].data[0]
                                        )}`,
                                        width: `${topThreeRisks[0].data[0]}%`,
                                      }}
                                    ></div>
                                  </div> */}
                                <ProgressBarHome
                                  idle={true}
                                  category={topThreeIndicis[0]}
                                  score={topThreeRisks[0]?.data[0]}
                                />
                              </div>
                              <div className="w-[100%]">
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[1]}
                                </div>
                                {/* <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                    <div
                                      class="h-6  rounded-full dark:bg-blue-500"
                                      style={{
                                        backgroundColor: `${pickColor(
                                          topThreeRisks[0].data[1]
                                        )}`,
                                        width: `${topThreeRisks[0].data[1]}%`,
                                      }}
                                    ></div>
                                  </div> */}
                                <ProgressBarHome
                                  idle={true}
                                  category={topThreeIndicis[1]}
                                  score={topThreeRisks[0]?.data[1]}
                                />
                              </div>
                              <div>
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[2]}
                                </div>
                                {/* <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                    <div
                                      class="h-6  rounded-full dark:bg-blue-500"
                                      style={{
                                        backgroundColor: `${pickColor(
                                          topThreeRisks[0].data[2]
                                        )}`,
                                        width: `${topThreeRisks[0].data[2]}%`,
                                      }}
                                    ></div>
                                  </div> */}
                                <ProgressBarHome
                                  idle={true}
                                  category={topThreeIndicis[2]}
                                  score={topThreeRisks[0]?.data[2]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      className="h-9 py-3 mt-12 px-4 inline-flex justify-center w-full items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                      data-hs-overlay="#hs-bg-gray-on-hover-cards"
                      onClick={handleReportClick}
                    >
                      {t("View Full Report")}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  <div className="bg-card text-card-foreground bg-white border border-gray-400 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                    <div className="flex flex-col space-y-8 p-6">
                      <h3 className="font-semibold text-sm leading-none tracking-tight">
                        {t("Composite Risk Score")}
                      </h3>
                      {isLoading ? (
                        <>
                          <div className="h-[12rem]  items-center mx-auto flex">
                            <BeatLoader
                              color={"#87CEEB"}
                              size={50}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <IdleGauge />
                        </>
                      )}

                      <div>
                        {/* <h2 className="font-semibold text-sm leading-none tracking-tight mt-2">
                      CLIMATE ADAPTATION PLAN
                    </h2> */}

                        {/* <p className="text-sm mt-3">
                      {riskData?.adaptations[1].Suggestion}
                    </p> */}
                      </div>

                      <div>
                        <h3
                          className="h-4 bg-gray-200 rounded-full dark:bg-gray-700  "
                          style={{ width: "40%" }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
            <TopGaugeCattle
              idle={isComputed}
              pillar={t("CLIMATE")}
              categories={climate_indices}
              firstScore={profitData?.total_scores?.climate_scores.drought_risk}
              secondScore={
                profitData?.total_scores?.climate_scores
                  ?.temperature_humidity_index_risk
              }
              composite_climate_risk={
                profitData?.total_scores?.climate_scores?.composite_climate_risk
              }
              tooltip={scoreTooltipData[0].tip}
            />
            <TopGaugeCattle
              idle={isComputed}
              pillar={t("WATER")}
              categories={water_indices}
              firstScore={Math.floor(
                profitData?.total_scores?.water_scores.water_stress_risk.toString()
              )}
              secondScore={Math.floor(
                profitData?.total_scores?.water_scores?.ground_water_risk.toString()
              )}
              composite_climate_risk={
                profitData?.total_scores?.water_scores?.composite_water_risk
              }
              tooltip={scoreTooltipData[0].tip}
            />
            <TopGaugeCattle
              idle={isComputed}
              pillar={t("PROFITABILITY")}
              categories={profitability_indices}
              firstScore={Math.floor(
                profitData?.total_scores?.profitability_scores
                  .profitability_risk
              )}
              composite_climate_risk={
                profitData?.total_scores?.profitability_scores
                  .profitability_risk
              }
              tooltip={scoreTooltipData[0].tip}
            />
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <NewsFeed />
            </div>
          </div>
          {/* modal */}
          <>
            {/* <Button onClick={() => setOpenModal(true)}>Toggle modal</Button> */}
            <Modal
              onClose={() => {
                setStep(0);
                type.current = null;
                selectedOption.current = null;
                setSumbit({});
                setOpen(false);
                setOption(null);
              }}
              show={open}
            >
              <Modal.Header className="items-center">
                {t("Please answer the following questions")}
              </Modal.Header>
              <Modal.Body className=" min-h-[200px]">
                {isLoading ? (
                  <>
                    <BeatLoader
                      color={"#87CEEB"}
                      // loading={loading}
                      cssOverride={override}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>
                ) : (
                  <div className="space-y-6">
                    {questions?.map((question, index) => {
                      const choicesObjects = question?.choices?.map(
                        (choice) => ({
                          label: choice,
                          value: choice,
                        })
                      );
                      if (step === index) {
                        type.current = question.category_type;
                      }
                      // if (sumbmit[type.current]) {
                      //   selectedOption.current = {
                      //     value: sumbmit[type.current],
                      //     label: sumbmit[type.current],
                      //   };
                      // }

                      return (
                        <>
                          {step === index && (
                            <>
                              <div key={index} className="font-regular">
                                {t("Question")} {index + 1}/{questions?.length}
                              </div>
                              <div className="font-semibold">
                                {question?.question}
                              </div>
                              <div className="z-10 mt-3">
                                {choicesObjects.length === 0 ? (
                                  <input
                                    type="number"
                                    className="border border-gray-300 p-2 rounded-md dark:text-black "
                                    value={inputValue}
                                    onChange={(e) =>
                                      setinputValue(e.target.value)
                                    }
                                  />
                                ) : (
                                  <Select
                                    placeholder={t("Select...")}
                                    className="dark:text-black z-2"
                                    value={
                                      (selectedOption.current && {
                                        value: selectedOption.current,
                                        label: selectedOption.current,
                                      }) ||
                                      (sumbmit[type.current] && {
                                        value: sumbmit[type.current],
                                        label: sumbmit[type.current],
                                      })
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                        color: "black",
                                      }),
                                    }}
                                    options={choicesObjects}
                                    onChange={handleChange}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="py-3">
                <div className="flex justify-between items-center w-full">
                  <Button
                    disabled={step === 0}
                    className="bg-transparent text-[#0e7490] border border-cyan-700 hover:text-white"
                    onClick={handlePrev}
                  >
                    {t("Previous")}
                  </Button>
                  {step === questions?.length - 1 ? (
                    <Button
                      color="gray"
                      className="bg-cyan-700 text-white"
                      onClick={handlesub}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button
                      color="gray"
                      className="bg-cyan-700 text-white"
                      onClick={handleNext}
                    >
                      {t("Next")}
                    </Button>
                  )}
                </div>
              </Modal.Footer>
            </Modal>

            <Modal
              onClose={() => {
                setOpenModal(false);
              }}
              show={openModal}
            >
              <Modal.Header className="items-center">
                {t("How do you keep your animals")}
              </Modal.Header>
              <Modal.Body
                className="min-h-[200px]"
                style={{ overflow: "visible" }}
              >
                {isLoading ? (
                  <>
                    <BeatLoader
                      color={"#87CEEB"}
                      // loading={loading}
                      cssOverride={override}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>
                ) : (
                  <div className="space-y-6">
                    {questions?.map((question, index) => {
                      const choicesObjects = question?.choices?.map(
                        (choice) => ({
                          label: choice,
                          value: choice,
                        })
                      );
                      if (step === index) {
                        type.current = question.category_type;
                      }

                      return (
                        <>
                          {step === index && (
                            <>
                              <div key={index} className="font-regular">
                                {t("Question")} {index + 1}/{questions?.length}
                              </div>
                              <div className="font-semibold">
                                {question?.question}
                              </div>
                              <div className="z-10 mt-3">
                                {choicesObjects.length === 0 ? (
                                  <input
                                    type="number"
                                    className="border border-gray-300 p-2 rounded-md dark:text-black "
                                    value={inputValue}
                                    onChange={(e) =>
                                      setinputValue(e.target.value)
                                    }
                                  />
                                ) : (
                                  <Select
                                    placeholder={t("Select...")}
                                    className="dark:text-black"
                                    value={
                                      (selectedOption.current && {
                                        value: selectedOption.current,
                                        label: selectedOption.current,
                                      }) ||
                                      (sumbmit[type.current] && {
                                        value: sumbmit[type.current],
                                        label: sumbmit[type.current],
                                      })
                                    }
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                        color: "black",
                                      }),
                                    }}
                                    options={choicesObjects}
                                    onChange={handleChangeFirstQuestion}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="py-3"></Modal.Footer>
            </Modal>
          </>
        </>
      </MainDashboard>
    </>
  );
}
