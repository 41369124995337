// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  point,
  polygon,
  multiPolygon,
  booleanPointInPolygon,
} from "@turf/turf";
import axios from "../api/axios";

import { RISK_SCORING_SERVICE_BASE_URL } from "../constants";
import { msalInstance, acquireAccessToken } from "../authConfig";

async function isInProtectedArea(coordinates) {
  const pointFeature = point(coordinates);
  const postCord = { latitude: coordinates[1], longitude: coordinates[0] };
  console.log(postCord, "post");

  try {
    let parsedAToken = await acquireAccessToken(msalInstance);
    // console.log(parsedAToken, "token");

    const response = await axios.post(
      `${RISK_SCORING_SERVICE_BASE_URL}/v1/location_scores/is-in-protected-area`,
      postCord,
      {
        headers: {
          // oid: localStorage.getItem("oid"),
          Authorization: `Bearer ${parsedAToken}`,
        },
      }
    );
    console.log(response.data, "full_api");
    console.log(response.data.geojson, "api");

    return [response?.data?.status, response?.data?.geojson, response?.data?.message, response?.data?.geojson?.iucn_color];
  } catch (error) {
    console.error("Error fetching or processing GeoJSON data:", error);
    return false;
  }
}

const wait = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(); // Resolve the Promise after the specified milliseconds
    }, milliseconds);
  });
};

export { isInProtectedArea, wait };
